<template>
  <main>
    <div v-if="errorOffering" class="container page-content text-center">
      <h4>This offering is not currently published.</h4>
      <p>
        Once this offering is published, you can return here to invest
      </p>
      <router-link :to="{ name: 'Offerings' }" class="btn btn-lg btn-primary"
        >Current Offerings</router-link
      >
    </div>
    <div v-if="offering && user">
      <div
        v-if="!offering.published"
        class="container page-content text-center"
      >
        <h4>This offering is not currently published.</h4>
        <p>
          Once this offering is published, you can return here to invest.
        </p>
        <router-link :to="{ name: 'Offerings' }" class="btn btn-lg btn-primary"
          >Current Offerings</router-link
        >
      </div>
      <div v-if="offering.published" class="container page-content">
        <div class="row pb-3">
          <div id="logo-holder">
            <img :src="offering.logoURL" class="card-img" />
          </div>
          <div class="col">
            <h1 id="offering-title">{{ offering.name }}</h1>
            <p id="offering-tagline">{{ offering.description }}</p>
          </div>
        </div>
        <div v-if="isInvestSuccess">
          <div class="row text-center m-3">
            <h5>Congratulations, your investment was successful!</h5>
            <p>You will be hearing from the issuer soon.</p>
          </div>
          <div class="row text-center m-3">
            <div class="col">
              <router-link
                :to="{ name: 'Offering', params: { id: offering.id } }"
                class="btn btn-primary"
              >
                Back to the offering details
              </router-link>
            </div>
            <div class="col">
              <router-link :to="{ name: 'Offerings' }" class="btn btn-primary">
                View other offerings
              </router-link>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row pb-3">
            <div class="form-outline mb-4">
              <h5>
                Before investing, you must agree to the following statements:
              </h5>
              <ol>
                <li>I have reviewed Round Here's educational materials</li>
                <li>
                  I understand that the entire amount of my investment may be
                  lost.
                </li>
                <li>
                  I am in a financial condition to bear the loss of the
                  investment.
                </li>
                <li>
                  There are restrictions on my ability to cancel an investment
                  commitment and obtain a return of my investment.
                </li>
                <li>
                  It may be difficult or impossible for me to resell the
                  investment interests I am purchasing.
                </li>
                <li>
                  This investment involves risk, and I agree that I can afford
                  to lost the entire amount of this investment.
                </li>
                <li>
                  I understand and agree that this funding portal, Round Here,
                  LLC, does not guarantee the performance of this investment or
                  any level of return.
                </li>
              </ol>
              <div v-if="user.emailVerified" class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="isRiskAccepted"
                  v-model="isRiskAccepted"
                />
                <label class="form-check-label" for="flexCheckChecked">
                  I have read, understand, and accept the risks outlined in the
                  above statements.
                </label>
              </div>
              <div v-else>
                <h6>You must validate your email address.</h6>
                <p>
                  When you signed up, you received a validation email with a
                  link to validate your email. If you don't have that email, you
                  can request a new one on your account page,
                  <router-link :to="{ name: 'Account' }">here</router-link>.
                </p>
              </div>
            </div>
          </div>
          <div class="error">{{ errorPage }}</div>
          <div v-if="offering.isAcceptingInvestments" class="row text-center">
            <div v-show="isRiskAccepted" class="col">
              <!-- Fund America Invest Now Button -->
              <!-- <button type="button" style="position: absolute, left: 0" class="btn btn-primary btn-block"
                :data-fa-invest-now="faOfferingCode" @click="investingNow" @fa-investnow-close="faClose">
                Invest Now
              </button> -->
              <div class="col">
                <router-link
                  :to="{
                    name: 'OfferingInvestments',
                    params: { id: offering.id }
                  }"
                  class="btn btn-primary"
                >
                  Invest Now
                </router-link>
              </div>
            </div>
            <div v-if="!isRiskAccepted" class="col">
              <button class="btn btn-primary" disabled>
                Invest Now
              </button>
            </div>
            <div class="col">
              <router-link
                :to="{ name: 'Offering', params: { id: offering.id } }"
                class="btn btn-secondary"
              >
                Cancel
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <Footer v-if="offering || errorOffering" />
</template>

<script>
import getDocument from "@/composables/getDocument";
import { ref } from "@vue/reactivity";
import Footer from "@/components/navigation/Footer.vue";
import getUser from "@/composables/getUser.js";

export default {
  props: ["id", "faId"],
  components: {
    Footer
  },
  setup(props) {
    const errorPage = ref(null);
    const isRiskAccepted = ref(false);
    const isInvestSuccess = ref(false);
    const { user } = getUser();

    const { error: errorOffering, document: offering } = getDocument(
      "offerings",
      props.id
    );

    return {
      isRiskAccepted,
      isInvestSuccess,
      errorOffering,
      offering,
      user,
      errorPage
    };
  }
};
</script>

<style scoped>
/* ADD PADDING TO BOTTOM OF PAGE TO ACCOUNT FOR OVERLAY MENU */
footer.container {
  margin-bottom: 100px;
}

#logo-holder {
  width: 200px;
  padding-top: 10px;
}

.btn-link {
  text-decoration: none;
}
</style>